import React, { useEffect, useState, useCallback } from 'react';

import './App.css';
import { Login, useIsSignedIn } from '@microsoft/mgt-react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Box, Grid, TextField, List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Paper, Container, AppBar, Toolbar } from '@mui/material';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Providers } from '@microsoft/mgt-element';
import PhoneIcon from '@mui/icons-material/Phone';
import LaptopIcon from '@mui/icons-material/Laptop';
import { Agenda } from '@microsoft/mgt-react';
import { ThemeProvider, createTheme, styled } from '@mui/material/styles';

import logo from './logo.png';
import pleoLogo from './pleo.png';
import hrHubLogo from './hrhub.png';
import crhLogo from './crh.png';
import synLogo from './syn.png';
import mRoomLogo from './mRoomLogo.png';
import clientDataLogo from './clientDataLogo.png';
import iCompleatLogo from './iCompleatLogo.png';
import msftPortalLogo from './msftPortalLogo.png';
import xeroLogo from './xero.png';
import lloydsLogo from './lloyds.png';
import fathomLogo from './fathom.png';
import itsupportLogo from './itsupport.png';
import streamtimeLogo from './streamtimeLogo.png';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import BadgeIcon from '@mui/icons-material/Badge';

// Define types
interface Device {
  displayName: string;
  manufacturer: string;
  model: string;
  deviceType: string;
}

interface Site {
  name: string;
  webUrl: string;
}

// Styled components
const ListItems = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  overflow: 'auto',
}));

// Define a fixed theme for the header
const headerTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#25083e',
    },
    text: {
      primary: '#ffffff',
    },
    background: {
      default: '#25083e',
    },
  },
});

const HeaderContainer = styled(AppBar)(({ theme }) => ({
  backgroundColor: "#25083e",
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
}));

const CardContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const cardData = [
  {
    image: clientDataLogo,
    title: 'Client Data',
    description: 'Go to central SharePoint location to access client data.',
    url: 'https://camargue.sharepoint.com/_layouts/15/sharepoint.aspx'
  },
  {
    image: crhLogo,
    title: 'Central Resources',
    description: 'Centralised platform or location where teams can access, manage, and share essential documents.',
    url: 'https://camargue.sharepoint.com/sites/CRH'
  },
  {
    image: hrHubLogo,
    title: 'HR Hub',
    description: 'A central platform for accessing human resources, such as employee benefits.',
    url: 'https://camargue.sharepoint.com/sites/HR_Hub'
  },
  {
    image: mRoomLogo,
    title: 'Rooms',
    description: 'Book meeting rooms in our groups office locations.',
    url: 'https://apps.powerapps.com/play/e/default-46d4e256-b3be-4371-9b3c-480cbc8d7489/a/f95044d6-4723-4790-867e-3d05365ddfb3?tenantId=46d4e256-b3be-4371-9b3c-480cbc8d7489&source=sharebutton&sourcetime=1723220431195#'
  },
  {
    image: pleoLogo,
    title: 'Pleo',
    description: 'A corporate card and expense management platform.',
    url: 'https://app.pleo.io/login'
  },
  {
    image: synLogo,
    title: 'Synergist',
    description: 'A project management and job costing system for agencies.',
    url: 'https://camarguegroup.synergist.cloud'
  },
  {
    image: msftPortalLogo,
    title: 'Microsoft Portal',
    description: 'A gateway to Microsoft\'s cloud services like Office 365, and more.',
    url: 'https://portal.office.com'
  },
  {
    image: itsupportLogo,
    title: 'IT Support',
    description: 'SharePoint site for accessing quick fix and cyber security information.',
    url: 'https://camargue.sharepoint.com/sites/HR_Hub/SitePages/Cyber-Security.aspx'
  },
  {
    image: iCompleatLogo,
    title: 'iCompleat',
    description: 'A cloud-based invoice automation and processing system.',
    url: 'https://portal.compleat.online/'
  },
  {
    image: xeroLogo,
    title: 'Xero',
    description: 'An online accounting software for small businesses.',
    url: 'https://login.xero.com/identity/user/login?ReturnUrl=%2Fidentity%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dxero_business_xeroweb_hybrid-web%26redirect_uri%3Dhttps%253A%252F%252Fgo.xero.com%252Fsignin-oidc%26response_mode%3Dform_post%26response_type%3Dcode%2520id_token%26scope%3Dxero_all-apis%2520openid%2520email%2520profile%26state%3DOpenIdConnect.AuthenticationProperties%253Dn9uVSAdZxOmsFZdo4SYy90Y0NXvoCS8jzawGZ8NAzmjK2I1R9dwe__zZ88JfbbLTiuLFKx8IG7FEK3aJLyCL9vE1A_EzkWUYeDPVfVe8j5ETd0IdnkDE-WYL3EfuEc1nImKAzFK45CXizOxAFGwLaRQaIHwlwgy0yN7G8PvRv3MedmbLnX_ZpVeyW8hifTLfx_ekt0QGXGYHi2M_vB0SG4U_QNA%26nonce%3D638461142653020714.ZTdjMzEwYWQtZDZiNS00NGRhLWJlNjUtZmQ0YThlOGNhOWU2NGRlOTIyZmMtNzQ2OC00NWI5LWJmMGYtODhjNzIxZTc2ZTk3%26x-client-SKU%3DID_NET451%26x-client-ver%3D1.3.14.0'
  },
  {
    image: lloydsLogo,
    title: 'Lloyds',
    description: 'A banking platform used for financial services and transactions.',
    url: 'https://cbonline.lloydsbank.com/PrimaryAuth/'
  },
  {
    image: fathomLogo,
    title: 'Fathom',
    description: 'A financial analysis and reporting tool for business performance tracking.',
    url: 'https://app.fathomhq.com/login?ReturnUrl=%2fmy-companies'
  },
  {
    image: streamtimeLogo,
    title: 'StreamTime',
    description: 'StreamTime is JFDs agency management system.',
    url: 'https://jamesforddesignteam.app.streamtime.net/#dashboard'
  }
];

// Card component
const InfoCard: React.FC<{ image: string; title: string; description: string; url: string }> = React.memo(({ image, title, description, url }) => (
  <Card sx={{ maxWidth: 345, display: 'flex', flexDirection: 'column', height: '275px', width: '265px' }} onClick={() => window.open(url, '_blank')}>
    <CardActionArea sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <CardMedia component="img" height="140" image={image} alt={title} />
      <CardContent sx={{ flex: 1 }}>
        <Typography gutterBottom variant="h5" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
));

// Fetch functions
const fetchSharePointSites = async (): Promise<Site[]> => {
  try {
    const graphClient = Providers.globalProvider.graph.client;
    const response = await graphClient.api('/sites?search=*CLIENT*&$top=100').get();
    return response.value;
  } catch (error) {
    console.error('Error fetching SharePoint sites:', error);
    return [];
  }
};

const fetchUserDevices = async (): Promise<Device[]> => {
  try {
    const graphClient = Providers.globalProvider.graph.client;
    const response = await graphClient.api('/me/ownedDevices?$select=displayName,manufacturer,model,deviceType').get();
    return response.value;
  } catch (error) {
    console.error('Error fetching user devices:', error);
    return [];
  }
};

// Device List component
const DeviceList: React.FC<{ devices: Device[] }> = React.memo(({ devices }) => (
  <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
    {devices.map((device, index) => (
      <ListItem key={index}>
        <ListItemAvatar>
          <Avatar>
            {device.deviceType === 'Phone' ? <PhoneIcon /> : <LaptopIcon />}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={device.displayName}
          secondary={`${device.manufacturer} ${device.model}`}
        />
      </ListItem>
    ))}
  </List>
));

// Virtualized List component
const VirtualizedList: React.FC<{ sites: Site[] }> = ({ sites }) => {
  const handleClick = useCallback((url: string) => {
    window.open(url, '_blank');
  }, []);

  const renderRow = (props: ListChildComponentProps) => {
    const { index, style } = props;
    const site = sites[index];

    return (
      <ListItem style={style} key={index} component="div" disablePadding>
        <ListItemButton onClick={() => handleClick(site.webUrl)}>
          <ListItemText primary={site.name} />
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Box sx={{ width: '100%', height: 400, bgcolor: 'background.paper' }}>
      <FixedSizeList
        height={400}
        width="100%"
        itemSize={46}
        itemCount={sites.length}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </Box>
  );
};

// Main App component
const App: React.FC = () => {
  const [isSignedIn] = useIsSignedIn();
  const [sites, setSites] = useState<Site[]>([]);
  const [devices, setDevices] = useState<Device[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [jobTitle, setJobTitle] = useState<string | null>(null);
  const [department, setDepartment] = useState<string | null>(null);

  useEffect(() => {
    const getSites = async () => {
      const siteData = await fetchSharePointSites();
      setSites(siteData);
    };

    const getDevices = async () => {
      const deviceData = await fetchUserDevices();
      setDevices(deviceData);
    };

    const fetchUserProfile = async () => {
      try {
        if (isSignedIn) {
          const graphClient = Providers.globalProvider.graph.client;
          const userProfile = await graphClient.api('/me').select('jobTitle,department').get();
          setJobTitle(userProfile.jobTitle);
          setDepartment(userProfile.department);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    if (isSignedIn) {
      getSites();
      getDevices();
      fetchUserProfile();
    }
  }, [isSignedIn]);

  // Filter cards based on job title
  const filteredCardData = cardData.filter(card => {
    // Show iCompleat card only for specific job titles
    if (card.title === 'iCompleat') {
      return jobTitle === 'Account Director' || jobTitle === 'Associate Director' || jobTitle === 'Director' || department === 'Finance';
    }

    // Show Finance-specific cards
    if (card.title === 'Xero' || card.title === 'Lloyds' || card.title === 'Fathom' || card.title === 'StreamTime') {
      return department === 'Finance';
    }

    return true; // Show all other cards regardless of job title
  });

  const filteredSites = sites.filter(site =>
    site.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="app">
      <ThemeProvider theme={headerTheme}>
        <HeaderContainer position="static">
          <Toolbar>
            {/* Left side: Logo */}
            <img src={logo} alt="Logo" style={{ height: 40, marginRight: 16 }} />

            {/* Spacer to push content to the right */}
            <div style={{ flexGrow: 1 }} />

            {/* Right side: Account button */}
            <PopupState variant="popover" popupId="demo-popup-menu">
              {(popupState) => (
                <React.Fragment>
                  <Button
                    variant="contained"
                    {...bindTrigger(popupState)}
                    startIcon={<BadgeIcon />}
                  >
                    Account
                  </Button>
                  <Menu
                    {...bindMenu(popupState)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',  // Anchoring to the bottom-left of the button
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',  // The top-right of the menu will attach to the anchor point
                    }}
                    PaperProps={{
                      style: {
                        marginLeft: '-165px',  // Move the menu to the left by 175px
                      },
                    }}
                  >
                    <MenuItem onClick={popupState.close}><Login /></MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </Toolbar>
        </HeaderContainer>
      </ThemeProvider>

      <Container>
        {isSignedIn && jobTitle && (
          <>
            <Box sx={{ flexGrow: 1, marginTop: 4 }}>
              <Grid container spacing={2} justifyContent="center" alignItems="center">
                {filteredCardData.map((card, index) => (
                  <CardContainer item xs={12} sm={6} md={3} key={index}>
                    <InfoCard {...card} />
                  </CardContainer>
                ))}
              </Grid>

              <Grid container spacing={3} justifyContent="center" alignItems="flex-start" sx={{ marginTop: 0 }}>
                {/* First Column */}
                <Grid item xs={12} sm={4} md={4}>
                  <ListItems>
                    <Typography variant="h6">Upcoming Appointments</Typography>
                    <Agenda groupByDay={true} />
                  </ListItems>
                </Grid>

                {/* Second Column */}
                <Grid item xs={12} sm={4} md={4}>
                  <ListItems>
                    <Typography variant="h6">Cloud Client Data</Typography>
                    <Box sx={{ padding: 2 }}>
                      <TextField
                        fullWidth
                        label="Search Clients"
                        variant="outlined"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                      />
                    </Box>
                    <VirtualizedList sites={filteredSites} />
                  </ListItems>
                </Grid>

                {/* Third Column */}
                <Grid item xs={12} sm={4} md={4}>
                  <ListItems>
                    <Typography variant="h6">Your Devices</Typography>
                    <DeviceList devices={devices} />
                  </ListItems>
                </Grid>
              </Grid>


            </Box>
          </>
        )}
        <br />
      </Container>
    </div>
  );
};

export default App;
