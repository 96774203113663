import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";

Providers.globalProvider = new Msal2Provider({
  clientId: 'abbb103f-65dd-4b74-9bf9-c3fb5add3994',
  authority: 'https://login.microsoftonline.com/46d4e256-b3be-4371-9b3c-480cbc8d7489', // Add tenant ID if not multi-tenant
  redirectUri: 'https://portal.camargue.uk',
  scopes: ['user.read','Sites.read.all','Calendars.Read','Device.read.all','DeviceManagementManagedDevices.Read.All']
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
